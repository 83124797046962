<template>
  <addDetail></addDetail>
</template>

<script>
import addDetail from './components/addDetail'
import authRouter from 'mixins/authRouter'
export default {
  components: {
    addDetail
  },
  mixins: [authRouter],
  created () {
    this.$store.commit("layouts/setPathList", [{ name: '经纪人列表', path: '/agentmanage/agentlist' }, { name: '新增' }]);
    this.$store.commit("layouts/changeBreadcrumb", true)
  },
  destroyed () {
    this.$store.commit("layouts/changeBreadcrumb", false)
  }
}
</script>

<style lang="less" scoped>
</style>